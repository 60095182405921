<template>
  <div grid="~ cols-2 gap-x-2 gap-y-4" mt="4">
    <div v-for="item in shownRecommendList" :key="item.azgoId" flex="~ col" @click="clickCard(item, ENUM_CARD_FROM.HOME_RECOMMEND)">
      <div class="relative">
        <van-image :src="item.coverImageUrl" fit="cover" style="vertical-align: middle;" w="full" flex="shrink-0" radius="0.5rem" class="aspect-square" />
        <div rounded="2" class="absolute bottom-0" w="full" h="full" style=" background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.67%, #000000 100%);"></div>
        <!-- <div
          class="absolute" top="2" left="2" bg="#00000033" rounded="1" h="4" px="1" font="300" text="2.5 white" flex="~ items-center" style="backdrop-filter: blur(4px)"
        >
          {{ item.parentLabelNameList?.[0] || '' }}
        </div> -->

        <div class="absolute bottom-2 truncate" w="full" px="2" text="sm #FFFFFF">
          <div mt="1" flex="~ items-center">
            <template v-if="item.rating">
              <div flex="~ items-center" style="color: var(--tc-color-primary);">
                <NuxtIcon mt="-0.5" name="attr-icon:star" text="xs" />
                <span text="xs" font="300">{{ item.rating }}</span>
              </div>
              <!-- <van-divider vertical style="padding: 0.15rem; margin: 0 0.25rem;" /> -->
            </template>
            <!-- <span text="xs white" font="300">{{ item.subLabelNameList?.[0] || '' }}</span> -->
          </div>
        </div>
      </div>
      <div flex="1 ~ col">
        <div flex="1">
          <div mt="2" text="#121212 sm" font="500" line-clamp="2">
            {{ item.title }}
          </div>
          <div v-if="item.countryName || item.cityName" mt="2" text="#808080 xs" font="300" flex="~ items-center">
            {{ item.countryName }}
            <template v-if="item.cityName && item.countryName !== item.cityName">
              <van-divider vertical style="padding: 0 0.25rem;" />
              {{ item.cityName }}
            </template>
          </div>
        </div>
        <div v-if="item.minPriceRecords?.[0]?.minPrice" mt="5" font="500" text="base #121212">
          <span text="2.5 #B2B2B2" font="300" mr="1">{{ t('key.attraction.detail.price.from') }}</span>{{ getCurrencyPrice(item.minPriceRecords?.[0]?.minPrice) }}
        </div>
      </div>
    </div>
  </div>

  <div v-if="(shownRecommendList?.length ?? 0) < (data?.length ?? 0)" text="center">
    <button class="azgo-haptics-feedback hover:bg-#0000000A" cursor="pointer" mt="8" rounded="2" px="12" h="9" b="1 solid #121212" text="sm #121212" @click="loadMore">
      {{ t('key.home.recommend.viewmore') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { SearchDTO } from '~/apis/back/types'
import { ENUM_CARD_FROM } from '~/enums'

const { data } = defineProps({
  data: {
    type: Array as PropType<SearchDTO[]>,
  },
})

const { getCurrencyPrice, clickCard } = useSearchResult()

const { t } = useI18n()
const pageNum = ref(1)
function loadMore() {
  pageNum.value++
}

// 假分页, 只显示前8个
const shownRecommendList = computed(() => data?.slice(0, pageNum.value * 8))
</script>

<style>

</style>
