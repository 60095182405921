<template>
  <div class="relative" mx="4" mt="8">
    <div class="font-500" text="lg #121212">
      {{ t('key.home.wheretonext.title') }}
    </div>
    <div mt="1" font="300" text="xs #808080">
      {{ t('key.home.wheretonext.desc') }}
    </div>
    <div ref="el" mt="4" w="full" overflow-x="auto" class="popular_destination">
      <div class="space-x-2" flex="~ items-center">
        <div v-for="item in popularCities" :key="item.countryCode" class="azgo-haptics-feedback relative" cursor="pointer" @click="onConfirmCity(item)">
          <van-image
            class="align-middle"
            :src="item.img"
            fit="cover"
            h="44"
            w="32"
            radius="0.5rem"
          />
          <div rounded="2" class="absolute bottom-0" w="full" h="full" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.38%, rgba(0, 0, 0, 0.8) 100%);"></div>
          <div class="absolute bottom-2 truncate" w="full" px="2" text="sm #FFFFFF">
            {{ item.destinationName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AttrDestinationDto } from '~/apis/back/types'
import { ENUM_EventType } from '~/enums'
import type { TopCityClicksEvent } from '~/types/event-tracking'

const el = ref<HTMLElement | null>(null)

function onConfirmCity(item: AttrDestinationDto & { countryCode?: string }) {
  console.log(item)
  trackData(item)
  return navigateWithLocale({
    path: '/rank',
    query: {
      ...getCommonQuery(),
      destinationName: item.destinationName,
      destinationId: item.destinationId,
      countryCode: item.countryCode,
    },
  })
}

const commonStore = useCommonStore()
const { t } = useI18n()

const popularCities = computed(() =>
  commonStore.commonData.cityInfo?.recommendDestinationList,
)

function trackData(item: AttrDestinationDto) {
  trackEvent<TopCityClicksEvent>({
    event: ENUM_EventType.TopCityClicks,
    click_city_id: `${item.destinationId}`,
    click_city: item.destinationName ?? '',
  })
}
</script>

<style>
.popular_destination::-webkit-scrollbar {
  display: none;
}
</style>
