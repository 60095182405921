<template>
  <div px="4" pt="8">
    <div text="#121212 lg" font="500">
      {{ t('key.home.recommend.title') }}
    </div>

    <!-- <van-tabs v-if="!showLoading" v-model:active="active" mt="2" class="azgo-tabs--mobile" animated swipeable shrink>
      <van-tab v-for="item in recommendList" :key="item.category" :title="t(`key.home.recommend.tab.${item.category?.replace(/-/g, '').toLowerCase()}`)" :name="item.category">
        <MobileIntelRecommendItem :data="item.recommendList" />
      </van-tab>
    </van-tabs> -->
    <MobileIntelRecommendItem v-if="!showLoading" :data="recommendList[0].recommendList" />
    <template v-else>
      <div mt="2">
        <!-- <div flex="~" m="4" gap="4">
          <VanSkeleton v-for="item in 4" :key="item" title w="30" p="0!" />
        </div> -->
        <div flex="~ wrap" justify="center" gap="4">
          <div v-for="item in 4" :key="item" :style="{ width: 'calc(50% - 8px)' }" rounded="4" overflow="hidden">
            <VanSkeletonImage w="full!" h="42!" />
            <div flex="~ col" gap="2" py="4">
              <VanSkeleton title w="50" />
              <VanSkeleton title w="40" />
              <VanSkeleton title w="60" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
const userStore = useUserStore()
const { t } = useI18n()
const {
  recommendList,
  // active,
  loading,
  fetchIntelRecommand,
} = useIntelRecommand()
const showLoading = computed(() => recommendList.value.length <= 1 && loading)
fetchIntelRecommand()
watch(() => userStore.userInfo.currency, () => {
  fetchIntelRecommand()
})
</script>
