<template>
  <div v-if="recentViewedList.length" px="4">
    <div pt="6" text="#121212 lg" font="500">
      {{ t('key.home.recentlyviewed.title') }}
    </div>
    <div mt="4" :grid="`~ flow-col gap-2 ${recentViewedList.length > 1 ? 'rows-2' : ''}`" overflow-x="auto">
      <div v-for="item in recentViewedList" :key="item.azgoId" flex="~ items-center" w="80" h="24" @click="clickCard(item, ENUM_CARD_FROM.RECENT_VIEWED)">
        <div class="relative">
          <van-image :src="item.coverImageUrl" fit="cover" style="vertical-align: middle;" h="24" w="24" flex="shrink-0" radius="0.5rem" />
          <!-- <div
            v-if="!!item.parentLabelNameList?.length"
            font="300" overflow="hidden" whitespace="nowrap" text-ellipsis
            :style="{ maxWidth: 'calc(100% - 8px)' }" class="absolute" top="1" left="1" bg="#00000033" rounded="1" h="3.5" px="1" text="2.5 white" flex="~ items-center"
            style="backdrop-filter: blur(4px)"
          >
            {{ item.parentLabelNameList?.[0] }}
          </div> -->
        </div>

        <div ml="3" h="full" flex="~ col">
          <div flex="1">
            <div text="sm #121212" line-clamp="2">
              {{ item.title }}
            </div>
            <div v-if="item.rating || item.subLabelNameList?.length" mt="1" flex="~ items-center">
              <template v-if="item.rating">
                <NuxtIcon name="attr-icon:star" text="xs" :style="{ color: 'var(--tc-color-score)' }" />
                <span text="3" font="300" :style="{ color: 'var(--tc-color-score)' }">{{ item.rating }}</span>
              </template>
              <!-- <van-divider v-if="item.rating && item.subLabelNameList?.length" vertical style="padding: 0.15rem; margin: 0 0.25rem;" />
              <span v-if="item.subLabelNameList?.length" text="3 #808080" font="300">{{ item.subLabelNameList?.[0] }}</span> -->
            </div>
          </div>
          <div v-if="item.minPriceRecords?.[0]?.minPrice" font="500" text="base #121212">
            <span text="2.5 #B2B2B2" font="300">{{ t('key.attraction.detail.price.from') }}</span> {{ getCurrencyPrice(item.minPriceRecords?.[0]?.minPrice) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ENUM_CARD_FROM } from '~/enums'

const { t } = useI18n()
const { recentViewedList, getCurrencyPrice, clickCard } = useSearchResult()
</script>

<style>

</style>
